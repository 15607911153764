.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}


.page-manintance__container{
  display: flex;
  height: 100%;
  
@media only screen and (max-width: 600px) {
    flex-direction: column;  
  }
}


.page-manintance__container .section1{
  flex: 0 0 35%;
  background-color:rgb(226, 226, 226);
  padding-top: 10rem;
  @media only screen and (max-width: 600px) {
    padding-top: 4rem;
  }
}
.page-manintance__container .section1 h1{
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0rem;
  color: #C8B346;
}
.page-manintance__container .section1 h2{
  font-family: "Sacramento", cursive;
  font-size: 40px;
  color: #C8B346;
  margin-top: 4rem;
  @media only screen and (max-width: 600px) {
    margin-top: 2rem;
  }
}
.page-manintance__container .section1 h3{

}



.page-manintance__container .section2{
  flex: 1 1 0%;
  background: url(./img/Bg-1.jpg) no-repeat;
  background-size: cover;
  color: #fff;
}
.page-manintance__container .section2_bg{
  background-color: #00000075;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    height: 160vh; 
  }
}
.page-manintance__container .section2 a{
  text-decoration: none;
  color: #000;
}
.page-manintance__container .section2 .abouttxt{
  display: flex;
  justify-content: center;
}
.page-manintance__container .section2 img{
  padding-top: 2%;
  width: 12rem;
}
.page-manintance__container .section2 h2{
  margin: 0;
}
.page-manintance__container .section2 p{
  width: 80%;
  text-align: center;
}

.page-manintance__container .section2 .contact-us{
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 600px) {
   flex-direction: column; 
   align-content: center;
    flex-wrap: wrap;
  }
}
.page-manintance__container .section2 .contact-us .contact1,.contact2,.contact3{
  background-color:rgb(226, 226, 226);
  height: 160px;
  width: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    height: 140px;
    width: 180px;
    margin-bottom: 2rem;
  }
}
.page-manintance__container .section2 .contact-us .contact1 span,.contact2 span,.contact3 span{ 
  color: #C8B346;
  padding-top: 2rem;
}

.page-manintance__container ul {
  list-style: none;
  padding: 0;
  
}
.page-manintance__container li{
  font-size: 16px;
  color: #fff;
  
}
.page-manintance__container .section2 ul li a{
  color: #fff;
  text-decoration: none;
}



